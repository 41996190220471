import { $authApi } from '~/api/global/ApiProvider';

import type { IInfoApi, IInfoApiData } from '~/features/techies/types/info/api.types';
import type { IInfoGamePlayingCoords } from '~/features/techies/types/info/client.types';
import { transform as transformInfo } from '~/features/techies/types/info/adapter';

import type { IStartApi } from '~/features/techies/types/start/api.types';

import type { IStepApi, IStepApiData } from '~/features/techies/types/step/api.types';
import { transform as transformStep } from '~/features/techies/types/step/adapter';

export default class TechiesRepository {
  private static API_URL = '/v1/miner';

  public static async getInfo() {
    const response = await $authApi.get<IInfoApi>(`${this.API_URL}/getUserStatus`);
    if ('msg' in response.data) throw new Error(response.data.msg ?? '');

    return transformInfo(response.data as IInfoApiData);
  }

  public static async postPaidStart(price: number) {
    const body = new FormData();
    body.append('type', 'paid');
    body.append('price', `${price}`);

    const response = await $authApi.post<IStartApi>(`${this.API_URL}/start`, { body });
    if ('msg' in response.data) throw new Error(response.data.msg ?? '');

    return true;
  }

  public static async postStep(coord: IInfoGamePlayingCoords) {
    const body = new FormData();
    body.append('item', `${coord.y}`);

    const response = await $authApi.post<IStepApi>(`${this.API_URL}/bet`, { body });
    if ('msg' in response.data) throw new Error(response.data.msg ?? '');

    return transformStep(response.data as IStepApiData);
  }
}
