import { EInfoApiTypes, type IInfoApiData } from './api.types';
import { EInfoTypes, type TInfoGame } from './client.types';

export const transform = (apiData: IInfoApiData): TInfoGame => {
  if (apiData.game.type === EInfoApiTypes.PLAYING) {
    return {
      coords: apiData.game.coords,
      type: EInfoTypes.PLAYING,
    };
  }

  return {
    type: EInfoTypes.DEFAULT,
  };
};
