export enum EStepStates {
  LOST = 'lost',
  NEXT = 'next',
  WON = 'won',
}

export interface IStepWon {
  caseLink: string;
  state: EStepStates.WON;
}

export interface IStepOther {
  state: EStepStates.LOST | EStepStates.NEXT;
}

export type TStep = IStepWon | IStepOther;
