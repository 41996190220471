export enum EInfoApiTypes {
  DEFAULT = 'default',
  PLAYING = 'playing',
}

export interface IInfoApiGameDefault {
  type: EInfoApiTypes.DEFAULT;
}

export interface IInfoApiGamePlayingCoords {
  x: number;
  y: number;
}

export interface IInfoApiGamePlaying {
  coords: IInfoApiGamePlayingCoords[];
  type: EInfoApiTypes.PLAYING;
}

export type TInfoApiGame = IInfoApiGameDefault | IInfoApiGamePlaying;

export interface IInfoApiData {
  balance: string;
  casePrice: string;
  game: TInfoApiGame;
}

export interface IInfoApiFailData {
  msg: string;
  status: false;
}

export interface IInfoApi {
  data: IInfoApiData | IInfoApiFailData;
  status: boolean;
}
