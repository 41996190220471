import type { IInfoGamePlayingCoords } from '~/features/techies/types/info/client.types';

export const usePlayerStore = defineStore('techies/player', () => {
  const coords = ref<IInfoGamePlayingCoords>({
    x: 0,
    y: 1,
  });
  const bombs = ref<IInfoGamePlayingCoords[]>([]);
  const history = ref<IInfoGamePlayingCoords[]>([]);

  return {
    bombs,
    coords,
    history,
  };
});
