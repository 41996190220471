export enum EInfoTypes {
  DEFAULT = 'default',
  PLAYING = 'playing',
}

export interface IInfoGameDefault {
  type: EInfoTypes.DEFAULT;
}

export interface IInfoGamePlayingCoords {
  x: number;
  y: number;
}

export interface IInfoGamePlaying {
  coords: IInfoGamePlayingCoords[];
  type: EInfoTypes.PLAYING;
}

export type TInfoGame = IInfoGameDefault | IInfoGamePlaying;
