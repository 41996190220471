import { BOMB_CHANCE } from '~/features/techies/constants/rules.constants';

export const getBombsCoords = (newX: number, newY: number) => {
  const bombs = newX < 4 ? 1 : 2;
  const availableY = [0, 1, 2].filter((y) => y !== newY);

  if (bombs === 2) return availableY.map((y) => ({ x: newX, y }));

  const bombIndex = Math.round(GlobalUtils.Maths.randomIntFromInterval(0, BOMB_CHANCE)) > BOMB_CHANCE / 2 ? 0 : 1;
  return [{ x: newX, y: availableY[bombIndex] }];
};
