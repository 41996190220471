export enum ETechiesEvents {
  GAME_COMPLETED = 'Techies Game completed',
  GAME_STARTED = 'Techies Game started',
}

export interface ITechiesGameCompletedEvent {
  'ending level': number;
}

export interface ITechiesGameStartedEvent {
  'game price': number;
  'starting level': number;
}
