import type { IStepApiData } from './api.types';
import { EStepStates, type TStep } from './client.types';

export const transform = (apiData: IStepApiData): TStep => {
  if (apiData.win === 0) {
    return {
      state: EStepStates.LOST,
    };
  }

  if (apiData.case) {
    return {
      caseLink: useLinks().OPEN_CASE + apiData.case,
      state: EStepStates.WON,
    };
  }

  return {
    state: EStepStates.NEXT,
  };
};
