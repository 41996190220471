import { ETechiesEvents, type ITechiesGameCompletedEvent, type ITechiesGameStartedEvent } from '../types/techies.types';

export class TechiesEvents {
  static gameCompleted(event: ITechiesGameCompletedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ETechiesEvents.GAME_COMPLETED,
      eventProperties: event,
    });
  }

  static gameStarted(event: ITechiesGameStartedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ETechiesEvents.GAME_STARTED,
      eventProperties: event,
    });
  }
}
